import axios from 'axios';

const API = 'admin-landing';

const ENDPOINTS = {
    getListaLandings(){
        return axios.get(`${API}/lista`)
    },
    getDetalleLanding(id_landing){
        return axios.get(`${API}/${id_landing}/detalle`)
    },
    getListaCedisDisponibles(id_landing){
        return axios.get(`${API}/${id_landing}/posibles-cedis/lista`)
    },
    crearLanding(payload){
        return axios.post(`${API}/crear`, payload)
    },
    agregarCedisALanding(payload){
        return axios.post(`${API}/agregar-cedis`, payload)
    },
    cambiarEstadoLanding(id_landing){
        return axios.put(`${API}/${id_landing}/cambiar-estado`)
    },
    editarLanding(payload){
        return axios.put(`${API}/editar`, payload)
    },
    borrarLanding(id_landing){
        return axios.delete(`${API}/${id_landing}/eliminar`)
    },
    eliminarCedisDeLanding(id_landing, id_cedis){
        return axios.delete(`${API}/${id_landing}/eliminar-cedis/${id_cedis}`)
    },
    loadSecciones(idLanding){
        return axios.get(`${API}/${idLanding}/get-secciones`)
    },
    detalleSeccion(idSeccion){
        return axios.get(`${API}/seccion/${idSeccion}/detalle`)
    },
    getRedesSociales(idLanding){
        return axios.get(`${API}/${idLanding}/lista/redes-sociales`)
    },
    editarRedesLanding(payload, idLanding){
        return axios.post(`${API}/${idLanding}/redes-sociales/anadir`, payload)
    }
};

export default ENDPOINTS;
