<template>
    <section>
        <div class="nav-personalisation py-2 px-3">
            <div class="row mx-0 mt-2 align-items-center" style="height:36px;">
                <i class="icon-back f-18 text-general cr-pointer" @click="$router.push({name: 'admin.paginas-inicio'})" />
                <template v-if="$route.name == 'admin.paginas-inicio.editar.construccion'">
                    <div class="col-3">
                        <el-input v-model="detallePage.nombre" placeholder="Nombre de la pagina" size="small" class="w-100" @input="editarNombrePage" @change="notificarCambio" />
                    </div>
                    <el-switch v-model="activo" class="switch-lquid" active-color="#DFE4E8" @change="cambiarEstadoPage" />
                    <div v-if="$can('boton_agregar_secciones_landing')" class="ml-auto my-auto" @click="RedesSocialesModal">
                        <div class="text-center br-20 cr-pointer px-3 mn-pill px-2 mr-3 mb-2 bg-fondo text-general f-14 border">
                            <p>
                                Redes sociales
                            </p>
                        </div>
                    </div>
                    <div class="col-3 ml-0">
                        <div class="manual-select px-3 cr-pointer" @click="cedis">
                            <span class="f-14 text-general">Cedis para la página</span>
                            <div class="space-cant text-white d-middle-center f-14 ml-auto">
                                {{ detallePage.cantidad_cedis }}
                            </div>
                            <i class="icon-angle-down f-15 text-general " />
                        </div>
                    </div>
                    <el-tooltip v-if="$can('boton_agregar_secciones_landing')" placement="bottom" content="Agregar elemento" effect="light">
                        <button type="button" class="btn-red" @click="crearSeccion">
                            <i class="icon-plus" />
                        </button>
                    </el-tooltip>
                    <el-popover placement="bottom" effect="light" popper-class="br-8 p-2">
                        <div v-if="$can('boton_crear_landing')" class="row mx-0 p-2 item-popover">
                            Duplicar página
                        </div>
                        <div v-if="$can('boton_eliminar_landing')" class="row mx-0 p-2 item-popover" @click="modalEliminarLanding">
                            Eliminar página
                        </div>
                        <button slot="reference" type="button" class="btn-action border ml-2">
                            <i class="icon-dots-vertical" />
                        </button>
                    </el-popover>
                </template>
                <div v-if="$route.name != 'admin.paginas-inicio.editar.construccion'" class="col-auto text-general f-600">
                    {{ detallePage.nombre }}
                </div>
            </div>
            <tabs :tabs="rutas" class="mt-4" />
        </div>
        <div class="content-page">
            <div class="row mx-0 py-5 my-2" />
            <router-view />
        </div>
        <!-- Partials -->
        <modal-cedis ref="modalCedis" :cedis="cedisAgregados" @actualizado="detalleLanding" />
        <modal-lateral-crear ref="modalCrear" @crear-elemento="componentsModal" />
        <modal-crear-titulo ref="modalCrearTitulo" />
        <modal-imagenes ref="modalCrearImagenes" @goEventsImages="goEventsImages" />
        <modal-anadir-enlace ref="modalAnadirEnlace" @redirectImages="goRedirectImages" />
        <modal-categorias ref="modalCategorias" />
        <modal-productos ref="modalProductos" />
        <modal-redes-sociales ref="modalRedesSociales" />
        <modal-eliminar ref="modalEliminarLanding" titulo="Eliminar página" mensaje="¿Desea eliminar esta página?" @eliminar="eliminarLanding" />
    </section>
</template>

<script>
import Landing from '~/services/landing/landing_admin'
import { mapGetters } from 'vuex'
export default {
    components: {
        modalCedis: () => import('./partials/modalCedis'),
        modalRedesSociales: () => import('./partials/modalRedesSociales'),
        modalLateralCrear: () => import('./partials/modaLateralCrear'),
        modalCrearTitulo: () => import('./partials/modalCrearTitulo'),
        modalImagenes: () => import('./partials/modalImagenes'),
        modalAnadirEnlace: () => import('./partials/modalEnlace'),
        modalCategorias: () => import('./partials/modalCategorias'),
        modalProductos: () => import('./partials/modalProductos')
    },
    data(){
        return {
            cedisAgregados: [],
            rutas: [
                { titulo: 'Construcción', ruta: 'admin.paginas-inicio.editar.construccion' },
                { titulo: 'Estadisticas', ruta: 'admin.paginas-inicio.editar.estadisticas', can:'tab_estadisticas_landing' },
            ],
            activo: false,
            detallePage: {},
        }
    },
    computed:{
        ...mapGetters({
            listener: 'landing/landing/getListenerUpdater',
        }),
        idLanding(){
            return Number(this.$route.params.idLanding)
        }
    },
    mounted(){
        this.detalleLanding();
    },
    methods: {
        cedis(){
            if(!this.$can('boton_gestionar_cedis_landing')){
                this.notificacion('Aceeso denegado','No tiene permiso para ejecutar esta acción, por favor pongase en contacto con el administrador','warning')
                return
            }
            this.$refs.modalCedis.toggle();
        },
        modalEliminarLanding(){
            this.$refs.modalEliminarLanding.toggle();
        },

        crearSeccion(){
            this.$refs.modalCrear.toggle();
        },
        RedesSocialesModal(){
            this.$refs.modalRedesSociales.toggle();
        },
        componentsModal(typElement){
            switch (typElement){
            case 1:
                this.crearSeccion();
                this.$refs.modalCrearTitulo.toggle('crear', null);
                break;
            case 2:
                this.crearSeccion();
                this.$refs.modalCrearImagenes.toggle('crear', null);
                break;
            case 3:
                this.crearSeccion();
                this.$refs.modalCategorias.toggle();
                break;
            case 4:
                this.crearSeccion();
                this.$refs.modalProductos.toggle();
                break;
            }
        },
        goEventsImages(data){
            switch(data.bandera){
            case 'asignar':
                return this.$refs.modalAnadirEnlace.toggle(data.idSection, data.idElementSection, 'asignar');
            case 'editar':
                return this.$refs.modalAnadirEnlace.toggle(data.idSection, data.idElementSection, 'editar');
            }
        },
        goRedirectImages(idSection){
            this.$refs.modalCrearImagenes.toggle('editar', idSection);
        },
        anadirEnlace(){
            this.$refs.modalCrearImagenes.toggle();
            this.$refs.modalAnadirEnlace.toggle();
        },
        async detalleLanding(){
            try {
                const { data } = await Landing.getDetalleLanding(this.idLanding)
                this.detallePage = data.detallepagina
                this.activo = this.detallePage.estado == 1 ? true : false
                this.cedisAgregados = data.detallepagina.cedis
            } catch (error){
                return this.error_catch(error)
            }
        },
        async editarNombrePage(){
            if(!this.$can('boton_crear_landing')){
                this.notificacion('Aceeso denegado','No tiene permiso para ejecutar esta acción, por favor pongase en contacto con el administrador','warning')
                return
            }
            try {
                const payload = {
                    nombreLanding : this.detallePage.nombre,
                    id_landing: this.idLanding
                }
                const { data } = await Landing.editarLanding(payload)
            } catch (error){
                return this.error_catch(error)
            }
        },
        async cambiarEstadoPage(){
            if(!this.$can('boton_crear_landing')){
                this.notificacion('Aceeso denegado','No tiene permiso para ejecutar esta acción, por favor pongase en contacto con el administrador','warning')
                return
            }
            try {
                const { data } = await Landing.cambiarEstadoLanding(this.idLanding)
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.error_catch(error)
            }
        },
        notificarCambio(){
            this.notificacion('Éxito', 'Nombre editado correctamente', 'success')
        },
        async eliminarLanding(){
            const { data } = await Landing.borrarLanding(this.idLanding)
            this.notificacion('Éxito', data.mensaje, 'success')
            this.$refs.modalEliminarLanding.toggle();
            this.$router.back();
        }
    }
}
</script>
<style lang="scss" scoped>
.nav-personalisation{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background-color: #FFFFFF;
    height: 112px;
    box-shadow: 0px 1px 4px #00000014;

    .manual-select{
        height: 36px;
        border: 1px solid #DFE4E8;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .space-cant{
            width: 24px;
            height: 22px;
            border-radius: 7px;
            background-color: #000000;
            color: #FFFFFF;
        }
    }
}
.item-popover:hover{
    cursor: pointer;
    background-color: #F6F9FB;
}
</style>
